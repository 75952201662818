<template>
    <div id="safebox" v-if="showcontent">
        <div class="disfelx heightcl">
            <div class="contentbox bgcolorcl flexcl">
                <span class="tipscl">{{ tipstext }}</span>
                <div class="btncl">
                    <span @click="cancel">{{ $t('message.Cancel') }}</span>
                    <span @click="confirm" style="color: #2776c3;">{{ $t('message.confirm') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showcontent: {
            type: Boolean,
            default: false,
        },
        tipstext: {
            type: String,
            default: '确认要删除此群组吗？'
        }
    },
    data() {
        return {
            cuttingPlatform: 0
        };
    },
    methods: {
        confirm() {
            this.$emit("confirmclosepop");
        },
        cancel() {
            this.$emit("closepop");
        },
    },
    created() {
        this.cuttingPlatform = localStorage.getItem("cuttingPlatform");
    }
};
</script>

<style lang='less' scoped>
#safebox {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    background-color: rgba(107, 107, 107, 0.5);

    .disfelx {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    .heightcl {
        height: 100%;
    }

    .contentbox {
        width: 400px;
    }

    .bgcolorcl {
        background-color: #ffffff;
        border-radius: 5px;
    }

    .tipscl {
        text-align: center;
        display: inline-block;
        margin: 20px;
    }

    .flexcl {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .btncl {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 400px;
        border-top: 1px solid #a8a8a8;

        span {
            padding: 10px 80px;
            cursor: pointer;
        }
    }

    .shoosecolor {
        color: #67c6c5;
    }
}
</style>