<template>
    <div class="container" @touchmove.prevent :style="$store.state.prohibitRoll ? 'overflow:hidden;' : ''">
        <el-table class="table" ref="multipleTable" :data="tableData" border tooltip-effect="dark"
            :style="'width: 100%;'" :height="$store.state.prohibitRoll ? '' : '100%'"
            @selection-change="handleSelectionChange">
            <!-- height="100%" -->
            <!-- height="250" -->
            <el-table-column type="selection" width="55" v-if="!bool" key="selection">
            </el-table-column>
            <el-table-column :label="$t('message.serialnumber')" type="index"
                :width="$store.state.language == 'zh' ? 55 : 120" v-if="bool" key="index">
            </el-table-column>
            <template>
                <el-table-column v-for="item in tableHead" :key="item.prop" :label="item.lable" :prop="item.prop"
                    :width="item.width">
                    <template slot-scope="scope">
                        <span v-if="item.prop == 'sos_status'" :class="scope.row.sos_status == 0 ? 'red' : ''">{{
        scope.row.sos_status | stateText
    }}
                        </span>
                        <span v-else-if="item.prop == 'MSG_TYPE'">
                            {{ scope.row[item.prop] | gbType }}
                        </span>
                        <div v-else-if="item.prop == 'MSG'">
                            <div v-if="scope.row.MSG_TYPE == 1">
                                {{ scope.row[item.prop] }}
                            </div>
                            <div v-else style="width:100%;">
                                <div class="dialog_right_top_dh">
                                    <div class="dialog_right_top_dh_left"></div>
                                    <div class="dialog_right_top_dh_right flex mouse" @click="playLy(scope.row)">
                                        <img v-if="scope.row.bool" style="width:11px;height: 13px;margin-left: 10px;"
                                            src="@/assets/history/gb_yy1.gif" alt="">
                                        <img v-else style="width:11px;height: 13px;margin-left: 10px;"
                                            src="@/assets/history/gb_yy.png" alt="">
                                        <div class="dialog_right_top_dh_right_text">{{ scope.row[item.prop] | gbTime }}
                                            <span style="position: relative;top: -4px;">·</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span v-else-if="item.prop == 'type'">
                            {{ scope.row[item.prop] | videoText }}
                        </span>
                        <span v-else-if="item.prop == 'sos_end_time'">
                            {{ scope.row.sos_status == 2 ? scope.row[item.prop] : '' }}
                        </span>
                        <span v-else-if="item.prop == 'efType'">
                            {{ scope.row[item.prop] | triggerMethod }}
                        </span>
                        <span v-else-if="item.prop == 'effectiveTimes'">
                            {{ scope.row[item.prop] | effectiveTimes }}
                        </span>
                        <span v-else-if="item.prop == 'groupName'">
                            {{ scope.row[item.prop] == '临时群组' ? $t('message.temporarygroupTow') : scope.row[item.prop] }}
                        </span>
                        <span v-else-if="item.prop == 'alarmTimestamp'">
                            {{ $moment(scope.row[item.prop]).format("YYYY-MM-DD") }}
                            {{ $moment(scope.row[item.prop]).format("HH:mm:ss") }}
                        </span>
                        <span v-else-if="item.prop == 'relieveAlarmTimestamp'">
                            <template v-if="scope.row[item.prop] != null">
                                {{ $moment(scope.row[item.prop]).format("YYYY-MM-DD") }}
                                {{ $moment(scope.row[item.prop]).format("HH:mm:ss") }}
                            </template>
                        </span>
                        <span v-else>{{ scope.row[item.prop] }}</span>
                    </template>
                </el-table-column>
            </template>
            <slot name="operation"></slot>
        </el-table>
    </div>
</template>

<script>
let seft;
import moment from 'moment';
import beasconfig from '@/api/beasconfig.js';
import EventBus from "@/eventBus/eventBus.js";
// import axios from 'axios';
export default {
    components: {},
    props: {
        tableData: {
            type: Array,
            default() {
                return [
                    // {
                    //     MSG: "测试msg_descriptor",//文字内容
                    //     MSG_TYPE: "1",//广播类型 1文本 3语音
                    //     MSG_UUID: "e78986180d584b9da4117f9e7492dbf6",
                    //     SEND_TIME: 1658820851006,//发送时间
                    //     fromName: "test"//发送人
                    // }
                ];
            }
        },
        tableHead: {    //表头
            type: Array,
            default() {
                return [];
            }
        },
        bool: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            music: null,
            userInfo: {} //用户
        };
    },
    created() {
        let that = this;
        that.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        EventBus.$on("stopPlayGb", function () {
            that.music = null;
        });
    },
    beforeCreate: function () {
        seft = this;
    },
    destroyed() {
        if (this.music != null) {
            this.music.pause()
            this.music = null;
        }
    },
    mounted() {

    },
    methods: {
        moment,
        handleSelectionChange(val) {
            this.$emit('getSelectiondata', val)
        },
        parseAudio() {       //暂停播放
            if (this.music != null) {
                this.music.pause()
                this.music = null;
            }
        },
        playLy(row) {       //播放录音
            let show = window.speechSynthesis && window.speechSynthesis.speaking ? window.speechSynthesis.speaking : false;     //判断是否有TTS播报在播放
            if (this.$store.state.voiceCallInfo.callType != 0) {
                this.$MessageWarning('正在语音通话！');
                return
            }
            if (this.$store.state.videoCallInfo.callType != 0) {
                this.$MessageWarning('正在视频呼叫！');
                return
            }
            if (show || this.$store.state.isSpeech || this.$store.state.isBroadcast || this.$store.state.calltThePolice != null) {     //有人讲话的时候不能播放

                return
            }
            const userState = JSON.parse(sessionStorage.getItem("userState"));
            let i = this.tableData.findIndex(item => item.bool == true);
            if (i >= 0 && row.SEND_TIME != this.tableData[i].SEND_TIME) {       //如果上一条有播放则直接播放下一条
                this.tableData[i].bool = false;
                this.music.pause();
                this.music = null;
                this.$emit('openPlay', row, false, 1)
            }
            if (this.music != null) {
                this.music.pause();
                this.music = null;
                this.$emit('openPlay', row, false)
            } else {
                console.log(`${beasconfig.mmsUrlapi}-${userState.server}/api/v1/media/download?user=${row.fromId}&uuid=${row.MSG_UUID}`)
                this.music = new Audio(`${beasconfig.mmsUrlapi}-${userState.server}/api/v1/media/download?user=${row.fromId}&uuid=${row.MSG_UUID}`);
                this.music.play()
                this.$emit('openPlay', row, true)
            }
        },
        muteFun() {      //静音
            let i = this.tableData.findIndex(item => item.bool == true);
            if (i >= 0 && this.$route.name == "radiobroadcast") {
                this.$emit('openPlay', this.tableData[i], false)
                this.music.pause()
                this.tableData[i].bool = false;
                this.music = null;
            }
        }
    },
    filters: {
        videoText: function (type) {
            return type == 101 ? '强拉视频' : type == 200 ? '视频呼叫' : '视频回传'
        },
        stateText: function (type) {

            return type == 0 ? seft.$t('message.Tobeprocessed') : seft.$t('message.Processed')
        },
        gbType: function (type) {

            return type == 1 ? '文字广播' : '语音广播'
        },
        gbTime: function (data) {

            return Math.round(data / 1000);
        },
        /*有效时段*/
        effectiveTimes(val) {
            if (val) {
                return val.replace("|", "~");
            } else {
                return '00:00 ~ 23:59'
            }
        },
        /*触发方式*/
        triggerMethod(type) {
            if (type == 1) {
                return seft.$t('message.Noentry')
            } else {
                return seft.$t('message.Forbidden')
            }
        }
    },
    watch: {
        //监听事件
        $route(val) {
            console.log(val)
        },
        '$store.state.isSpeech'(newVal) {       //有人讲话
            //对数据执行操作
            if (newVal) {
                this.muteFun();
            }
        },
        // "$store.state.sosPushInfo"(newVal) {        //sos
        //     //对数据执行操作
        //     if (newVal.length > 0) {
        //         if (this.$store.state.userState.sosVoice == 1) {
        //             let i = this.tableData.findIndex(item => item.bool == true);
        //             if (i >= 0 && this.$route.name == "radiobroadcast") {
        //                 this.$emit('openPlay', this.tableData[i], false)
        //                 this.music.pause()
        //                 this.tableData[i].bool = false;
        //                 this.music = null;
        //             }
        //         }
        //     }
        // },
        "$store.state.gbInfoText"(newVal) {        //文字广播
            //对数据执行操作
            if (newVal.length > 0) {
                this.muteFun();
            }
        },
        "$store.state.isBroadcast"(bool) {        //语音广播
            //对数据执行操作
            if (bool == true) {
                this.muteFun();
            }
        },
        "$store.state.isTextBb"(bool) {        //文字广播
            //对数据执行操作
            if (bool == true) {
                this.muteFun();
            }
        },
        "$store.state.calltThePolice"(newVal) {        //围栏报警
            //对数据执行操作
            if (newVal != null) {
                this.muteFun();
            }
        },
        "$store.state.videoCallInfo.callType"(type) {
            if (type != 0) {
                this.muteFun();
            }
        },
        "$store.state.voiceCallInfo.callType"(type) {
            if (type != 0) {
                this.muteFun();
            }
        }
    },
};
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 100%;
}

.table {
    width: 100%;
}

/deep/.el-table th>.cell {
    text-align: center;
    color: #000;
    font-weight: 700;
}

/deep/.el-table .el-table__cell {
    text-align: center;
    color: #000;
}

.red {
    color: red;
}

.dialog_right_top_dh {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 97%;
    margin: 0px auto;

    &_left {
        border-style: solid;
        border-color: transparent;
        border-width: 5px 5px 3px 0px;
        border-right-color: #327676;
        color: #fff;
    }

    &_right {
        width: 135px;
        background-color: #327676;
        border-radius: 5px;
        font-size: 12px;
        text-align: center;

        &_text {
            padding: 5px 5px;
            color: #fff;
            // line-height: 25px;
        }
    }

    &_rightOne {
        width: 149px;
        min-height: 55px;
        background-color: #327676;
        border-radius: 5px;
        font-size: 12px;

        img {
            width: 15px;
            height: 21px;
            margin-left: 15px;
        }

        span {
            margin-left: 10px;
        }
    }

    &_text {
        position: absolute;
        left: calc(80% + 16px);
        bottom: 0px;
        color: #fff;
    }
}

.flex {
    display: flex;
    align-items: center;
}
</style>